<template>
    <main>
        <div>
            <mcma-card title="Workflow d'import d'opération" icon="administration">
                <template #describe>
                    <p>Remplissez la grille, importer, puis passer à la prochaine étape (suite au retour d'import dans
                        la
                        grille
                        directement)</p>
                </template>
                <blockquote>
                    <div>
                        Etape n° {{ step.order + 1 }} : {{ step.label }}
                    </div>
                    <div>
                        {{ htmlGuide }}
                    </div>
                </blockquote>
                <a-row type="flex" :gutter="12" class="mt-0 mb-0">
                    <a-col>
                        <!--<mcma-button name="Réinitialiser la grille" @click="resetGrid"  :disabled="loading"/>-->
                        <v-btn block color="green" :disabled="loading || columnDefs === null" :loading="loading"
                            @click="resetGrid()">
                            Réinitialiser la grille
                            <v-icon right> refresh </v-icon>
                        </v-btn>
                    </a-col>
                    <a-col>
                        <v-btn block color="green" :disabled="loading || columnDefs === null" :loading="loading"
                            @click="save()">
                            Importer
                            <v-icon right> cloud_upload </v-icon>
                        </v-btn>
                    </a-col>
                    <!--<a-col>
                        <v-btn block color="green" :disabled="loading || columnDefs === null" :loading="loading"
                            @click="download()">
                            Télécharger la grille au format xlsx
                            <v-icon right> cloud_download </v-icon>
                        </v-btn>
                    </a-col>-->
                    <a-col>
                        <v-btn block color="green" :disabled="loading" :loading="loading" @click="nextStep()">
                            Passer à la prochaine étape
                            <v-icon right> subdirectory_arrow_right </v-icon>
                        </v-btn>
                    </a-col>
                    <a-col v-if="loading">
                        <span>Merci de patienter le temps du traitement...</span>
                        <span v-if="uploading">... le traitement est en cours...</span>
                    </a-col>
                </a-row>
                <a-row v-if="columnDefs" type="flex" :gutter="0" class="mt-0 mb-0">
                    <ag-grid-vue style="width: 100%;height: 800px; margin-top: 1%;" class="ag-theme-material"
                        :grid-options="gridOptions" :modules="modules" :columnDefs="columnDefs" :rowData="rowData"
                        @gridReady="onGridReady" />
                </a-row>
                <a-row v-else>
                    <mcma-spin-box :spinning="loading" :tip="tipMsg" :size="80" :full="true"></mcma-spin-box>
                    <p v-if="!loading"></p>
                    <a-result v-if="!loading" status="error" title="Erreur "
                        subTitle="Le pipeline associé à cette grille n'a pas été trouvé, veuillez contacter un administrateur. Merci de votre compréhension."></a-result>
                </a-row>
            </mcma-card>
            <mcma-konami-unlock @unlock="setSuperUnlock" />
        </div>
    </main>
</template>

<script>
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import { mapState } from "vuex"
import McmaCard from "@/components/mcma/common/McmaCard.vue"
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { AgGridVue } from "ag-grid-vue"
import McmaButton from "@/components/mcma/common/McmaButton.vue"
import Axios from "@/util/Axios"
import McmaKonamiUnlock from "@/components/mcma/common/McmaKonamiUnlock"
export default {
    name: "McmaImportWorkFlow",
    props: {
        steps: Array,
        operationId: Number,
        membreId: Number,
    },
    components: {
        AgGridVue,
        McmaCard,
        McmaButton,
        McmaKonamiUnlock,
        McmaSpinBox,
    },
    data() {
        return {
            operation: null,
            membre: null,
            rowData: null,
            columnDefs: null,
            modules: AllModules,
            gridOptions: {
                enableRangeHandle: true,
                enableRangeSelection: true,
                stopEditingWhenGridLosesFocus: false,
                processCellFromClipboard: null,
                // turns OFF row hover, it's on by default
                //suppressRowHoverHighlight: true,
                // turns ON column hover, it's off by default
                columnHoverHighlight: true,
            },
            gridApi: null,
            columnApi: null,
            cols: null,
            htmlGuide: null,
            loading: false,
            uploading: false,
            step: null,
            stepIndex: 0,
            debug: false,
            tipMsg: "Veuillez patienter pendant le chargement de la grille...",
            // Steps qui composent notre wkflow d'import, pour un ajout, il suffit d'ajouter une ligne avec un pipename existant côté mage..
            // Généraliser le composant, puis passer cette var en props pour appeler le composant où on veut dans l'app avec diff workflow ;)
            /*steps: [
                { key: 'insertMember', label: 'Insérer des membres', order: 0, pipename: 'wp_insert_members' },
                { key: 'insertUsers', label: 'Insérer des utilisateurs', order: 1, pipename: 'wp_insert_users' },
                { key: 'insertInterloc', label: 'Insérer des interlocuteurs', order: 2, pipename: 'wp_insert_interlocuteurs' },
                { key: 'insertPdl', label: 'Insérer des sites', order: 3, pipename: 'wp_insert_sites' },
                { key: 'insertDTElec', label: 'Insérer des données techniques ELEC', order: 4, pipename: 'wp_insert_sites_elec_dt' },
                { key: 'insertDTGaz', label: 'Insérer des données techniques GAZ', order: 5, pipename: 'wp_insert_sites_gaz_dt' },
                { key: 'linkPdlInterloc', label: 'Lier des PDL à leur interlocuteur technique', order: 6, pipename: 'wp_link_pdl_interlocuteur' },
                { key: 'insertPf', label: 'Insérer des périmètres de facturation', order: 7, pipename: 'wp_insert_pf' },
                { key: 'linkPdlPf', label: 'Lier des PDL à leur PF', order: 8, pipename: 'wp_link_pdl_pf' },
                { key: 'linkPdlMembre', label: 'Lier des PDL à leurs membres', order: 9, pipename: 'wp_link_pdl_membre' },
                { key: 'linkUserMembre', label: 'Lier des utilisateurs à leurs membres', order: 10, pipename: 'wp_link_user_membre' }
            ]*/
        }
    },
    computed: {
        ...mapState(["operations"]),
        getOperations() {
            //console.log("oui", this.operations)
            return this.operations
        },
    },
    watch: {
        debug: {
            immediate: true,
            handler(v) {
                if (this.debug) {
                    console.log('v : %O', v)
                    console.log('this.cols : %O', this.cols)
                    if (this.cols !== undefined && this.cols !== null) {
                        this.cols.forEach((columnDef) => {
                            if (columnDef.display === 'debug') {
                                this.columnApi.setColumnVisible(columnDef.name, v);
                            }
                        })
                    }
                }

            }
        },
    },
    created() {
        this.findOperationFromId()
        //this.findMembreFromId()
        this.initGrid()
    },
    methods: {
        findOperationFromId() {
            this.operation = this.operations.find((o) =>
                o.id === this.operationId
            )
            console.log("this.operation", this.operation, this.operations)
        },
        findMembreFromId() {
            return null
        },
        setSuperUnlock() {
            this.debug = true
        },
        nextStep() {
            if (this.stepIndex + 1 < this.steps.length) {
                this.stepIndex += 1
            } else {
                this.stepIndex = 0
            }
            this.initGrid()
        },
        resetGrid() {
            this.debug = false
            this.initGrid()
        },
        initGrid() {
            this.step = this.steps.find((s) => s.order == this.stepIndex)
            this.initRows()
        },
        addLackingParams(rows){
            // add operation_nom and membre_nom if we are in ope or member to avoid users completion errors/oversights
            rows.forEach((r) => {
                if(r.operation_nom === undefined && this.operation !== undefined && this.operation !== null){
                    r.operation_nom = this.operation.name
                }
                //need to do the same for memberId
            })
        },
        async save() {
            try {
                console.log('Start Save : %O', this.rowData)
                const nonEmptyRows = this.rowData.filter(
                    ({ id, ...data }) => !Object.values(data).every((v) => v == null)
                )

                if (nonEmptyRows.length == 0) {
                    //this.$toast.error('Veuillez renseigner au moins une ligne.')
                    //this.errorMessage = 'Veuillez renseigner au moins une ligne.'
                    this.$notification.warning({
                        message: "Grille vide",
                        description: `Veuillez remplir au moins une ligne pour importer.`,
                    })
                    //console.log("error -> lignes vides ! ")
                    return
                }
                this.addLackingParams(nonEmptyRows)

                this.loading = true

                const data = []
                try {
                    var firstRow = this.columnDefs.map((cd) => cd.field)
                    //console.log(firstRow)
                    data.push({ ...firstRow })
                    for (const row of nonEmptyRows) {
                        //this.$delete(row, 'id')
                        const rowData = { ...row }
                        data.push(rowData)
                    }
                } catch (error) {
                    this.$toast.error(error)
                    console.error(error)
                    return
                }
                // On pousse les données vers Waika qui transmet à Mage
                // En retour on obtient le pathS3 du fichier de réponse 
                console.log('data : %O', data)
                const res = await Axios("post",
                    '/mage/treatData?pipeline=' + this.step.pipename + '&tag=import',
                    data
                )

                console.log('resjson : %O', res)
                if (res.data.pipeline_run?.event_variables?.waika_path_s3_return) {
                    // Dans le cas où un fichier retour est attendu
                    // On lance un deuxième appel à Bondo pour attendre le fichier retour
                    this.uploading = true
                    const waika_path_s3_return = res.data.pipeline_run.event_variables.waika_path_s3_return
                    console.log('waika_path_s3_return : %O', waika_path_s3_return)
                    const dataret = await Axios("get",
                        '/file/s3/download_from_path?path=' + waika_path_s3_return + '&timeout=3600&bucket=store-waika&isFile=true'
                    )

                    console.log('typeof dataret : %O', typeof dataret)
                    if (typeof dataret.data === 'object' && dataret.data !== null) {
                        console.log('dataret est bien de type objet JavaScript.');
                        this.rowData = dataret.data
                        this.cols.forEach((columnDef) => {
                            if (columnDef.display === 'out') {
                                this.columnApi.setColumnVisible(columnDef.name, true);
                            }
                        })
                    } else {
                        console.log('dataret n\'est pas un objet JavaScript.');
                        this.$toast.error("Désolé, une erreur technique s'est produite lors du chargement du retour du traitement... Veuillez essayer avec un ensemble de données moins volumineux, s'il vous plaît.")
                    }
                    this.uploading = false
                }
            } catch (error) {
                console.error(error)
                this.$toast.error()
            } finally {
                this.loading = false
                console.log('save end');
            }

        },
        download() {
            //this.gridApi.exportDataAsCsv()
            //this.gridApi.exportDataAsExcel()
        },
        autoSizeAllCols(skipHeader) {
            const allColumnIds = []
            this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId)
            })
            this.gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader)
        },
        onGridReady({ columnApi, api }) {
            this.columnApi = columnApi.columnController
            this.gridApi = api
            //this.autoSizeAllCols()
            //console.log("columnapi", this.columnApi)
        },
        initRows() {
            this.loading = true
            this.initColumnsDefs()
            this.rowData = [...Array(5000)].map((e, index) => ({
                id: index + 1,
            }))
            setTimeout(() => {
                this.loading = false
            }, 500)
        },
        async setPipeVariables(step) {
            this.columnDefs = null
            this.cols = null
            const res = await Axios("get", "mage/getPipeVars?pipename=" + step.pipename)
            console.log("res =>", res)
            if (res.data.variables !== undefined && res.data.variables !== null) {
                const grille_template = res.data.variables.find((v) => v.block.uuid === 'global').variables.find((v) => v.uuid === 'grille_template')
                console.log('setPipeVariables grille_template : %O', grille_template)
                this.cols = grille_template.value
                const htmlGuide = res.data.variables.find((v) => v.block.uuid === 'global').variables.find((v) => v.uuid === 'guide')
                this.htmlGuide = htmlGuide?.value
                console.log('setPipeVariables htmlGuide : %O', htmlGuide)
            }
        },
        async initColumnsDefs() {
            await this.setPipeVariables(this.step)
            //this.setColsTest(this.step)
            if (this.cols !== null) {
                const columnDefs = [
                    {
                        headerName: 'Ligne',
                        field: 'id',
                        suppressMenu: true,
                        width: 50,
                    },
                    ...this.cols.map(({ name, label, type, display, validator, needed }) => ({
                        field: name,
                        editable: display && display === 'in' ? true : false,
                        resizable: true,
                        hide: display && display !== 'in' ? true : false,
                        headerName: label,
                        cellRenderer: name == 'Pdf' ? 'PdfCell' : null,
                        headerClass: needed && needed == 2 ? 'orange' : needed && needed == 1 ? 'yellow' : 'blue',
                        valueParser: ({ newValue }) => {
                            const val = typeof newValue == 'string' ? newValue.trim() : newValue

                            if (!val) return null
                            if (type == 'DOUBLE') {
                                if (typeof val == 'string') {
                                    return Number(parseFloat(val.replace(',', '.')).toFixed(4))
                                }
                            }
                            return val
                        },
                        cellClassRules: {
                            ...(name === 'ligne_ko' && {
                                // dont know why red dont works here
                                orange: ({ value }) => {
                                    return value == 2
                                },
                                yellow: ({ value }) => {
                                    return value == 1
                                },
                                green: ({ value }) => {
                                    return value == 0
                                }
                            }),
                            ...(type == 'String' && {
                                red: ({ value }) => {
                                    var regexp = new RegExp(validator)
                                    // if !value --> do not display red cell
                                    if (value && !regexp.test(value)) {
                                        Materialize.toast("La valeur insérée ne correspond pas à la valeur attendues: format attendues -> " + regexp, 4000, "deep-orange white-text")
                                        console.log('Ne passe pas la regexp : %O | %O', value, regexp)
                                    }
                                    return value && !regexp.test(value)
                                }
                            }),
                            ...(type == 'DOUBLE' && {
                                red: ({ value }) => {
                                    return typeof value === 'number' && isNaN(value)
                                },
                            }),
                            ...(type == 'DATE' && {
                                red: ({ value }) => {
                                    return value
                                    //return value && !isValid(parse(value, 'dd/MM/yyyy', new Date()))
                                },
                            }),
                        },
                    })),
                ]
                console.log('columnDefs : %O', columnDefs)
                this.columnDefs = columnDefs
            } else {
                this.columnDefs = null
            }

        },
    },
}
</script>

<style lang="scss" scoped></style>